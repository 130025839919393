
import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.$message.warning('모바일만 이용이 가능합니다.');
  }

  private loading = false;
}
